.global {
  font-family: "Thasadith", sans-serif;
}

.global h2 {
  font-size: 50px;
  color: #bfbab9;
}

.global h3 {
  color: #a8a1a1;
}

.global p {
  margin-bottom: 0;
  color: #bfbab9;
  font-size: 30px;
  padding: 10px 0;
  line-height: 28px;
}

.global ul {
  padding-left: 0;
  margin-bottom: 0;
  font-size: 30px;
  list-style-position: inside;
}

.global li {
  color: #bfbfbf;
}

.global a {
  transition: color 0.4s;
  color: #bfbfbf;
}

.global i {
  vertical-align: middle;
  padding-top: 6px;
}

.global a:hover {
  color: black;
  cursor: pointer;
  font-weight: 900;
  text-decoration: none;
}

@media screen and (max-width: 1024px) {
  .global p {
    font-size: 20px;
    line-height: 25px;
  }

  .global ul {
    font-size: 20px;
  }
}
