.Section_section__GbnZA {
  background-size: cover;
  background-position: center;
  position: relative;
  display: table;
  min-height: 100vh;
  width: 100%;
  padding: 20px 0;
}
.Section_children__SCk5D {
  display: table-cell;
  vertical-align: middle;
}

.Nav_nav__3DcyL ul li {
  text-align: center;
  font-size: 65px;
  font-weight: 500;
  list-style-type: none;
}

.Nav_list__3oaOe li:first-child {
  position: fixed;
  bottom: 2%;
  left: 2%;
  z-index: 1000;
}

@media screen and (max-width: 1024px) {
  .Nav_nav__3DcyL ul li {
    font-size: 55px;
  }
}

.LinkedInBubble_bubble__2XI29 {
  position: fixed;
  top: 4%;
  right: 2%;
  transition: all 0.2s;
  z-index: 1000;
  opacity: 0.79;
}

.LinkedInBubble_bubble__2XI29:hover {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
  cursor: pointer;
}

.LinkedInBubble_face__2k30y {
  width: 160px;
  height: 156px;
  border: 6px solid #ffff;
  border-radius: 100%;
}

.LinkedInBubble_icon__2Hm7X {
  bottom: 0;
  color: #0077b5;
  font-size: 55px;
  right: 0;
  position: absolute;
}
@media screen and (max-width: 1024px) {
  .LinkedInBubble_face__2k30y {
    width: 80px;
    height: 78px;
    border: 1px solid #ffff;
  }

  .LinkedInBubble_icon__2Hm7X {
    font-size: 30px;
  }
}

.global_global__3579B {
  font-family: "Thasadith", sans-serif;
}

.global_global__3579B h2 {
  font-size: 50px;
  color: #bfbab9;
}

.global_global__3579B h3 {
  color: #a8a1a1;
}

.global_global__3579B p {
  margin-bottom: 0;
  color: #bfbab9;
  font-size: 30px;
  padding: 10px 0;
  line-height: 28px;
}

.global_global__3579B ul {
  padding-left: 0;
  margin-bottom: 0;
  font-size: 30px;
  list-style-position: inside;
}

.global_global__3579B li {
  color: #bfbfbf;
}

.global_global__3579B a {
  transition: color 0.4s;
  color: #bfbfbf;
}

.global_global__3579B i {
  vertical-align: middle;
  padding-top: 6px;
}

.global_global__3579B a:hover {
  color: black;
  cursor: pointer;
  font-weight: 900;
  text-decoration: none;
}

@media screen and (max-width: 1024px) {
  .global_global__3579B p {
    font-size: 20px;
    line-height: 25px;
  }

  .global_global__3579B ul {
    font-size: 20px;
  }
}

.ResumeContent_list__4u10j li:last-child a {
  text-decoration: underline;
  color: #b3bad6;
}

.typingGame_sentence__4xz9N {
  white-space: pre-wrap;
  font-size: 30px;
  color: #bfbab9;
}

.typingGame_textarea__1N7ni.typingGame_textarea__1N7ni.typingGame_textarea__1N7ni {
  font-size: "1.1rem";
  color: black;
  font-weight: 600;
}

.typingGame_firstChar__242H2 {
  background-color: #912521;
  position: absolute;
  white-space: pre;
}

