.section {
  background-size: cover;
  background-position: center;
  position: relative;
  display: table;
  min-height: 100vh;
  width: 100%;
  padding: 20px 0;
}
.children {
  display: table-cell;
  vertical-align: middle;
}
