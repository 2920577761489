.bubble {
  position: fixed;
  top: 4%;
  right: 2%;
  transition: all 0.2s;
  z-index: 1000;
  opacity: 0.79;
}

.bubble:hover {
  transform: scale(1.15);
  cursor: pointer;
}

.face {
  width: 160px;
  height: 156px;
  border: 6px solid #ffff;
  border-radius: 100%;
}

.icon {
  bottom: 0;
  color: #0077b5;
  font-size: 55px;
  right: 0;
  position: absolute;
}
@media screen and (max-width: 1024px) {
  .face {
    width: 80px;
    height: 78px;
    border: 1px solid #ffff;
  }

  .icon {
    font-size: 30px;
  }
}
